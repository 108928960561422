<template>
  <div class="content">
    <div class="background">
      <div class="row">
        <div class="col">
          <h4>Reviews Documentation</h4>
        </div>
        <div class="col-auto">
          <router-link :to="{ name: 'reviews' }" class="mb-4 vave-btn">BACK</router-link>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <h4>Overview</h4>
          <div>
            <p>
              Product reviews can be downloaded from
              <a href="https://businessapp.b2b.trustpilot.com/product-reviews/">https://businessapp.b2b.trustpilot.com/product-reviews/</a>
            </p>
            <p>Then the CSV file can be uploaded using the tool provided</p>
            <div class="row">
              <div class="col-auto">
                <div class="alert alert-danger" role="alert">Every time a CSV is uploaded, the reviews are all replaced with the new ones, so make sure to export ALL the reviews.</div>
              </div>
            </div>
            <div class="row">
              <div class="col-auto" style="border: 1px solid black;">
                <img :src="images.trustpilot" width="800" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      images: {
        trustpilot: require("./../assets/images/trustpilot.png"),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
  padding: 20px;
}
</style>